/*
莅视医疗平台----pc
----服务器接口连接地址
//1、本地 2、开发wingstiger 3、海泰 4：正式环境的测试环境
*/
let sever_config = 3;

/*
----公司业务分平台
1、海泰新光 2、国药新光
*/
let web_act_source = 1;

/*
----旧配置资源项
1、wingstiger 2、eliscn 3、海泰新光

新配置资源项 1、wingstiger 2、eliscn 3、海泰新光  4、国药新光 5、rslaser测试
*/
let static_config = 3;

let mova_config = {
    env: 3,//1、安卓 2、ios 3、web
}

export {
    sever_config,
    web_act_source,
    mova_config,
    static_config
}
