import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
//
import "./iconfont/iconfont.css";
//import axios from 'axios'
import './plugins/element.js';

//iView组件引入
// import iView from 'view-design';
import 'view-design/dist/styles/iview.css';
import "./plugins/iview.js";
//iView使用
// Vue.use(iView);

//引入封装的非对称和对称加密方法
import crypt from './utils/cryptfun/rsacryptfun.js';
import symcrypt from './utils/cryptfun/symmetriccryptfun.js';
Vue.use(crypt);
Vue.use(symcrypt);

//引入一个定义的commonParam以避免重复工作
import commonParamfun from './utils/commonParam/commonParam.js';
Vue.use(commonParamfun);

//element-ui组件引入
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

//引入global 修改测试地址、正式地址以及不同的logo
import globalurls from './plugins/globalConfig/globalurl.js';
// import globalurls from '../../global_url/globalurl02';
Vue.prototype.global_ = globalurls.serverurl;
Vue.prototype.uploadpicurl = globalurls.uploadpicurl;
Vue.prototype.wsurl = globalurls.wsurl;
// 平台配置参数
Vue.prototype.$platFrom = globalurls.platFrom;
Vue.prototype.$platFromList = globalurls.platFromList;
Vue.prototype.$platFromList0 = globalurls.platFromList0;
Vue.prototype.$businessBelong = globalurls.businessBelong;
Vue.prototype.$knowledgeTypeList = globalurls.knowledgeTypeList;
// 平台对象
Vue.prototype._textLogo = globalurls.globalConfigAll.textLogo;
Vue.prototype._loginBc = globalurls.globalConfigAll.loginBc;
Vue.prototype._logoUrl = globalurls.globalConfigAll.logoUrl;
Vue.prototype._mobileUrl = globalurls.globalConfigAll.mobileUrl;
Vue.prototype._globalConfigAll = globalurls.globalConfigAll;
// 高德地图秘钥
Vue.prototype.$mapKey = globalurls.gaode_map_config;
// 业务全局参数类
import global_static from "@/static/global_static/global_static"
Vue.prototype.$global_static = new global_static();
//使用方式：this.global.serverurl

import knowledgeFunc from './utils/allFunction/knowledgeSubjectList'
Vue.use(knowledgeFunc)

//less-loader引入
import less from 'less';
//less使用
Vue.use(less);
//全局引入axios且挂在到原型链上
import axios from 'axios';
Vue.prototype.$axios = axios;


//echarts全局引入
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
// 引入播放插件
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
Vue.use(VueVideoPlayer);
//  引入节流文件
import "./utils/directives/debounce";
// 引入请求拦截器
import "./utils/disabledToken/disabledToken";
//element-ui使用
// Vue.use(ElementUI);
// 引入公共函数方法
import "./utils/tools/timeFormat";
import "./utils/tools/commonFun";

// 全局组件
import "./utils/common_components/index";

//lodash的mainjs引用
import _ from 'lodash';
Vue.prototype._ = _;
// 全局创建websocket链接方法
import createWS from './utils/websocket/create';
Vue.prototype.$createWS = createWS
// Vue2中引用Vue3的setup语法糖
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
// mova中的全局图片json文件
import mova_url from "./assets/mova_url.json"
Vue.prototype.$mova_url = mova_url;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
