import Vue from 'vue'
import VueRouter from 'vue-router'
// import { Store } from 'vuex';
// import { component } from 'vue/types/umd';
// import Layout from '../views/Layout.vue'
// import Home from '../views/viewsinlayout/Home'

//引入不同角色的主页页面
////"roleCode": 角色类型:  0: 系统管理员 , 1: 手术医生 ，2: 专家医生  , 3: 业务员
import child1 from './module/router1'
import child2 from './module/router2'
import child3 from './module/router3'
import child0 from './module/router0'
import child9 from './module/router9'
import meeting from "./module/meeting"
//console.log(child1);
import store from './../store'
Vue.use(VueRouter)

const homeChildren = [
  //   {
  //   path: 'Home',
  //   name: 'Home',
  //   component: Home
  // },

  // {
  //   path:'login',
  //   name:'Login',
  //   component:() => import('../views/Login.vue')
  // },
  // {
  //   path:'teamDetail',
  //   name:'teamDetail',
  //   component:()=>import('../views/viewsinlayout/teamDetail.vue')
  // },
  {
    path: 'caseDiscussion',
    name: 'caseDiscussion',
    component: () => import('../views/viewsinlayout/caseDiscussion.vue'),
    meta: {
      breadcrumb: [
        {
          value: '案例管理',
          level: 1
        },
        {
          value: ' 案例讨论',
          level: 1
        },
      ]
    }
  },

  {
    path: 'chUsrinfo',
    name: 'chUsrinfo',
    component: () => import('../views/viewsinlayout/chUsrinfo.vue'),
  },
  {
    path: 'chPwd',
    name: 'chPwd',
    component: () => import('../views/viewsinlayout/chPwd.vue'),
  },
  {
    path: 'newcaseDiscussion/:newcaseType/:newcaseusrId/:newcasecenterId',
    name: 'newcaseDiscussion',
    component: () => import('../views/viewsinlayout/newcaseDiscussion.vue'),
    // children: [
    //   {
    //     path: 'basicinformation',
    //     // component:()=>import('../components/newcaseDiscussion.vue')
    //     component: () => import('../components/newcaseDiscussion/basicimformation.vue'),
    //   }
    // ]
  },
  {
    path: 'caseDetail/:id',
    name: 'caseDetail',
    props: true,
    component: () => import('../views/viewsinlayout/caseDetail.vue')
  },
  {
    path: 'followUpManage',
    name: 'followUpManage',
    component: () => import('../views/viewsinlayout/followUpManage.vue'),
    meta: [
      {
        value: '案例管理',
        level: 1
      },
      {
        breadcrumb: { level: 1, value: ' 随访管理' }
      }],
    // children: [
    //   {
    //     path: 'basicinformation',
    //     // component:()=>import('../components/newcaseDiscussion.vue')
    //     component: () => import('../components/newcaseDiscussion/basicimformation.vue'),
    //   }
    // ]
  },

  // {path:"*",redirect:"/Login"}
];
let layoutChild = [...homeChildren,...child0.child0,...child1.child1,...child2.child2,...child3.child3,...child9.child9,...meeting];


const routes = [
  {
    path: '/', redirect: '/Login'
  },
  {
    path: '/Login', name: 'Login', component: () => import('../views/Login')
  },
  {
    path: '/Register', name: 'Register', component: () => import('../views/Register')
  },
  {
    path: '/Layout',
    name: 'Layout',
    component: () => import('../views/Layout.vue'),
    children: layoutChild,
  },
  {
    path: '/osstestpage',
    name: 'osstestpage',
    component: () => import('../plugins/oss_upload'),
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: './' + process.env.BASE_URL,
  // base:'./',
  routes
})



// router.beforeEach((to, from, next) => {
//   //如果页面不存在则到404页面：
//   // if(to.matched.length === 0)
//   // {
//   //   next('/404')
//   // }
//   // //如果没有token，则回到登录界面
//   // if(window.localStorage.getItem(userInfo))
//   // {
//   //   next()
//   // }
//   // else{
//   //   if(to.path === "/Login"||to.path ==="/Profile")
//   //   {
//   //     next()
//   //   }
//   //   else{
//   //     next('/Login')
//   //   }
//   // }
//   console.log(to,from,next,'tofromnext');
//   console.log(store,'store');
//   console.log(!store.state.userInfo.token||!JSON.parse(window.localStorage.getItem('vuex')).userInfo.token,'没有token真假')
//   console.log(store.state.userInfo.token,'token');
//   console.log(JSON.parse(window.localStorage.getItem('vuex')).userInfo.token,'vuex')
//   if(!store.state.userInfo.token||!JSON.parse(window.localStorage.getItem('vuex')).userInfo.token)
//   {
//   //   //如果是非login和register页面：就跳转登录页面：
//     if(to.path !== '/Login'||to.path !== '/Register')
//   //   {

//       // next('/Login');
//       return false;
//     }
//     else{
//     next();
//     }
//     next();
//   // }
//   // next('/Login');
// })



// router.beforeEach((to,from,next)=>{
// iView.LoadingBar.start();//loading效果
// Store.state.token = sessionStorage.getItem('token');//获取本地存储的token
// })
// JSON.parse(window.localStorage.getItem('vuex')).userInfo.token
router.beforeEach((to, from, next) => {

  let isAuthenticated = false;
  if(store)
  if(store.state)
  if(store.state.userInfo)
  isAuthenticated=store.state.userInfo.token;

  // console.log(isAuthenticated,typeof(isAuthenticated),'isAuthenticated,以token判断是否处于登录状态');
  // console.log(to,'totototoototo',from,'fromfromfromfromfromfromfrom',next,'1')
  // isAuthenticated = undefined;
  if ((to.name !== 'Login'&&to.name !== 'Register') && !isAuthenticated)
   {
    //  console.log('由于没有token不能去到内容页面所以跳去login')
     next({ name: 'Login' })
  }
  else{
    // console.log('只要有token或者只要去非内容页，畅通的')
    next()
  }
})

export default router
