<template>
  <div>
    <Modal
      v-model="valueShow"
      title="自定义宽度"
      width="300"
      :mask-closable="false"
      :styles="{ top: '250px' }"
      @on-visible-change="showChange"
    >
      <template #header>
        <p style="text-align: center; font-weight: bold; font-size: 14px">
          {{ title }}
        </p></template
      >
      <div class="ElisModalSmall-wrap">
        <slot></slot>
      </div>
      <template #footer>
        <div class="footerModal">
          <button @click="close()" style="cursor: pointer">取消</button>
          <button @click="deleteOk()" style="cursor: pointer">确认</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //   show: false,
    };
  },
  methods: {
    deleteOk() {
      this.$emit('ok');
      // this.$emit("update:showValue", false);
    },
    close() {
      this.$emit('cancel');
      // this.$emit("update:showValue", false);
    },
    showChange(val) {
      if (val == false) {
        this.$emit('cancel');
      }
    },
  },
  props: {
    showValue: {
      default: false,
    },
    title: {
      default: '提示',
    },
  },
  computed: {
    valueShow: {
      get() {
        return this.showValue;
      },
      set(value) {
        this.$emit('update:showValue', value);
      },
    },
  },
  watch: {
    // show: {
    //   handler(newV) {
    //     this.$emit("change", newV);
    //   },
    // },
  },
};
</script>

<style scoped>
.ElisModalSmall-wrap {
  width: 100%;
  height: 100%;
}
.footerModal {
  margin-right: 15px;
}
.footerModal button:nth-of-type(1) {
  width: 111px;
  height: 32px;
  background: white;
  border: 1px solid #00a8e3;
  box-shadow: 0px 3px 6px rgba(123, 123, 123, 0.16);
  opacity: 1;
  border-radius: 20px;
  color: #00a8e3;
}
.footerModal button:nth-of-type(2) {
  width: 111px;
  height: 32px;
  background: #00a8e3;
  border: 1px solid #00a8e3;
  box-shadow: 0px 3px 6px rgba(123, 123, 123, 0.16);
  opacity: 1;
  border-radius: 20px;
  color: white;
}
</style>